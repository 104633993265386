import clsx from 'clsx'
import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { motion } from 'framer-motion'

// ----------------------------------------------------------------------

Logo.propTypes = {
  className: PropTypes.string
}

function Logo({ className, offset, onlyIcon = false, ...other }) {
  // if (offset) {
  //   return <h1>Logo</h1>
  // return (
  //   <Box
  //     component="img"
  //     alt="logo"
  //     src="/static/brand/logo_full.png"
  //     height={64}
  //     className={className}
  //     style={{ transition: 'all .2s linear' }}
  //     {...other}
  //   />
  // )
  // }
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  if (onlyIcon) {
    return (
      <Box
        component="img"
        alt="logo"
        data-src={"/static/brand/logo-gradiente.png"}
        height={64}
        className={clsx(className, 'lazyload')}
        style={{ transition: 'all .2s linear', display: 'inline-block' }}
        {...other}
      />
    )
  }

  return (
    <>
      <Box
        component="img"
        alt="logo"
        data-src={"/static/brand/logo-gradiente.png"}
        height={64}
        className={clsx(className, 'lazyload')}
        style={{ transition: 'all .2s linear', display: 'inline-block' }}
        {...other}
      />
      {
        offset ? <></> :
          <motion.div
            initial="hidden"
            animate="visible"
            variants={variants}
            transition={{ duration: .3, }}
            style={{ display: 'inline-block' }}
          >
            <Box
              component="img"
              alt="logo"
              data-src={"/static/brand/logo_full.png"}
              height={64}
              className={clsx(className, 'lazyload')}
              // style={{ transition: 'all .2s linear', display: !offset ? 'inline-block' : 'none' }}
              {...other}
            />
          </motion.div>
      }
    </>
  )
}

export default Logo
