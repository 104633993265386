import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import { motion } from 'framer-motion'
import useBreakpoints from '~/hooks/useBreakpoints'

// ----------------------------------------------------------------------

ScrollLogo.propTypes = {
  className: PropTypes.string
}

function ScrollLogo({ className, ...other }) {
  const variants = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
  }

  const prevScrollY = useRef(0)

  const [goingUp, setGoingUp] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false)
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true)
      }

      prevScrollY.current = currentScrollY
      console.log(goingUp, currentScrollY)
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [goingUp])

  const isMobile = useBreakpoints('down', 'sm')

  return (
    <>
      <Box
        component="img"
        alt="logo"
        src={"/static/brand/logo-gradiente.png"}
        height={64}
        className={className}
        style={{ transition: 'all .2s linear', display: 'inline-block' }}
        {...other}
      />
      {
        !isMobile && (
          <motion.div
            initial={!goingUp ? "hidden" : "visible"}
            animate={!goingUp ? "hidden" : "visible"}
            variants={variants}
            transition={{ duration: .3, }}
            style={{ display: 'inline-block' }}
          >
            <Box
              component="img"
              alt="logo"
              src={"/static/brand/logo_full.png"}
              height={64}
              className={className}
              {...other}
            />
          </motion.div>
        )
      }
    </>
  )
}

export default ScrollLogo
